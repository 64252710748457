<template>
  <div class="container">

    <!--Breadcrumb 영역 s-->
    <div class="top-nav-container">
      <nav role="navigator">
        <ul class="breadcrumbs list-unstyled hide-sm">
          <li class="breadcrumb">제품</li>
          <li class="breadcrumb" v-html="getHead"/>
          <li class="breadcrumb" v-html="getTitle"/>
        </ul>
      </nav>
    </div>
    <!--Breadcrumb 영역 e-->

    <div class="section">
      <div v-if="html" v-html="html" @click="check($event)"/>
      <div class="b_lists" v-else>
        <div class="nolist">
          <img src="@/assets/images/icon_alert.png" alt="">
          <p>컨텐츠 <span>준비중</span>입니다.</p>
          <p>이용에 불편을 드려서 죄송합니다.<br>
            빠른 시일내에 컨텐츠를 준비하도록 하겠습니다.</p>
        </div>
      </div>
    </div>

    <div class="modal" v-if="showModal">
      <!-- 사이트 이탈 Modal의 내용 -->
      <div class="modal_contents">
        <div class="modal_close close">
          <a @click="closeModal">close</a>
        </div>
        <p class="modal_tit">현재 보고 계신<br> <strong>BMSON</strong> 웹사이트를 나갑니다.</p>
        <p class="modal_exp">현재 웹사이트를 나와 다른 외부 웹사이트와 연결됩니다. BMSON은 외부 사이트의 콘텐츠에 대한 책임을 지지 않으며, 해당 콘텐츠로 인한 손해나 손상에
          대한 법적 책임이 없습니다. 외부 사이트 링크는 단지 웹사이트 사용자들에게 편의를 제공하기 위함입니다.</p>
        <div class="modal_btn">
          <span class="btn_modal" @click="closeModal({url: url})">계속하기</span>
          <span class="btn_modal" @click="closeModal">취소</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Landing",
  data() {
    return {
      html: '',
      showModal: false,
      url: '',
    }
  },
  computed: {
    getHead() {
      return this.$store.state.menu.productMenu[this.$route.params.productName].head
    },
    getTitle() {
      return this.$store.state.menu.productMenu[this.$route.params.productName].title
    },
  },
  methods: {
    check() {
      return true
      // let {target} = event
      // this.findATag(target, event)
    },
    findATag(element, event) {
      if(!element.parentNode) {
        event.preventDefault()
        return false;
      } else if(element.tagName.toLowerCase() !== 'a'){
        return this.findATag(element.parentNode, event)
      } else {
        let {href, origin: targetOrigin} = element
        let {origin: locationOrigin} = location
        if (href) {
          let expUrl = /^http[s]?:\/\//i;
          if (expUrl.test(href)) {
            if (targetOrigin !== locationOrigin) {
              this.openExternalModal(element.href)
            } else {
              return true;
            }
          }
        }
      }
    },
    openExternalModal(url) {
      this.url = url
      this.showModal = true;
      document.documentElement.classList.add("overflow-y-hidden");
    },
    closeModal({url}) {
      if (url) {
        window.open(url, '_blank')
      }
      document.documentElement.classList.remove("overflow-y-hidden");
      this.showModal = false;
    },
  },
  watch: {
    $route() {
      axios.get(this.$store.state.menu.productMenu[this.$route.params.productName].landing)
          .then(({data}) => this.html = data)
          .catch(() => this.html = '')
    }
  },
  created() {
    axios.get(this.$store.state.menu.productMenu[this.$route.params.productName].landing)
        .then(({data}) => this.html = data)
        .catch(() => this.html = '')
    this.$emit('set-init-tab')
  },

}
</script>

<style scoped>
</style>
